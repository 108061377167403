import { Text, TextInput, ThemeIcon, Tooltip } from '@mantine/core'
import { IconFileDownload, IconLogout, IconSearch } from '@tabler/icons'
import React from 'react'
import styled from 'styled-components'
import { Upload } from './Upload'

const AlertDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1f70f2;
    box-shadow: 0 0 1px rgba(67, 90, 111, 0.3),
        0 5px 8px -4px rgba(67, 90, 111, 0.47);
`

interface ImpersonationAlertProps {
    impersonatedUserEmail?: string
    onUserChange: (impersonatedUserEmail?: string) => void
    onDownload: () => void
    onUpload: (file: File | null) => Promise<void>
}

const ImpersonationAlert = (props: ImpersonationAlertProps) => {
    const [textInputValue, setTextInputValue] = React.useState('')

    const beginImpersonation = async (impersonatedUserEmail?: string) => {
        try {
            await props.onUserChange(impersonatedUserEmail)
            setTextInputValue('')
        } catch {}
    }

    return (
        <AlertDiv>
            <div
                style={{
                    color: 'white',
                    padding: '10px',
                }}
            >
                {props.impersonatedUserEmail ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px',
                        }}
                    >
                        <Text>
                            😎 Impersonating{' '}
                            <b>{props.impersonatedUserEmail}</b>
                        </Text>
                        <Tooltip label="Back to your profile">
                            <ThemeIcon
                                variant="filled"
                                color="#1f70f2"
                                onClick={() => {
                                    beginImpersonation(undefined)
                                }}
                                style={{
                                    cursor: 'pointer',
                                }}
                            >
                                <IconLogout />
                            </ThemeIcon>
                        </Tooltip>
                    </div>
                ) : (
                    'Not impersonating'
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                }}
            >
                <TextInput
                    style={{ margin: '5px 15px 5px 5px' }}
                    icon={
                        <ThemeIcon variant="light">
                            <IconSearch />
                        </ThemeIcon>
                    }
                    rightSection={
                        <div>
                            <Text size={14}>@bigblue.io</Text>
                        </div>
                    }
                    rightSectionWidth={100}
                    rightSectionProps={{
                        style: {
                            border: '1px solid #ced4da',
                            backgroundColor: '#e8f5ff',
                            borderRadius: '0px 5px 5px 0px',
                        },
                    }}
                    value={textInputValue}
                    onChange={(e) => setTextInputValue(e.currentTarget.value)}
                    onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                            beginImpersonation(textInputValue + '@bigblue.io')
                        }
                    }}
                ></TextInput>
                <Upload
                    style={{ marginRight: '15px' }}
                    onUpload={props.onUpload}
                />
                <div style={{ marginRight: '15px' }}>
                    <Tooltip label="Download data">
                        <ThemeIcon
                            color="#1f70f2"
                            style={{ cursor: 'pointer' }}
                            onClick={props.onDownload}
                        >
                            <IconFileDownload />
                        </ThemeIcon>
                    </Tooltip>
                </div>
            </div>
        </AlertDiv>
    )
}

export { ImpersonationAlert }
