import { Table } from "@mantine/core";
import styled from "styled-components";

const ClickableTr = styled.tr`
  &:hover {
    background-color: #e7f5ff;
    cursor: pointer;
  }
`;

interface MilestonesTableProps {
  setExpectedVal: (arg: number) => void;
  setExpectedDil: (arg: number) => void;
}

const MilestonesTable = (props: MilestonesTableProps) => (
  <Table
    style={{
      flex: "0 0 400px",
      maxWidth: "400px" /* for Safari browser */,
    }}
  >
    <thead>
      <tr>
        <th>Milestones</th>
        <th>Valuation</th>
        <th>Total dilution</th>
      </tr>
    </thead>
    <tbody>
      <ClickableTr
        onClick={() => {
          props.setExpectedVal(47698674.46);
          props.setExpectedDil(0);
        }}
      >
        <td>Series A-2</td>
        <td>€47.7m</td>
        <td>-</td>
      </ClickableTr>
      <ClickableTr
        onClick={() => {
          props.setExpectedVal(200000000);
          props.setExpectedDil(0.3);
        }}
      >
        <td>Series B (target)</td>
        <td>€200m</td>
        <td>30%</td>
      </ClickableTr>
      <ClickableTr
        onClick={() => {
          props.setExpectedVal(600000000);
          props.setExpectedDil(0.5);
        }}
      >
        <td>Series C (target)</td>
        <td>€600m</td>
        <td>50%</td>
      </ClickableTr>
      <ClickableTr
        onClick={() => {
          props.setExpectedVal(2000000000);
          props.setExpectedDil(0.65);
        }}
      >
        <td>Exit (target)</td>
        <td>€2bn</td>
        <td>65%</td>
      </ClickableTr>
    </tbody>
  </Table>
);

export { MilestonesTable };
