import { Modal } from '@mantine/core'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import React from 'react'
import { BigBlueShortLogo } from './BigblueLogo'

interface LoginModalProps {
    opened: boolean
    onSuccess: (credentialResponse: CredentialResponse) => void
    onError: () => void
}

const LoginModal = (props: LoginModalProps) => {
    return (
        <Modal
            opened={props.opened}
            onClose={() => {}}
            closeOnClickOutside={false}
            closeOnEscape={false}
            size={350}
            title=""
            withCloseButton={false}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <BigBlueShortLogo size={60} />
                <b style={{ margin: '10px' }}>
                    Bigblue Stock Options Calculator
                </b>
                <GoogleLogin
                    onSuccess={props.onSuccess}
                    onError={props.onError}
                />
            </div>
        </Modal>
    )
}

export { LoginModal }
