import { Accordion, Text } from '@mantine/core'

interface InfoTextProps {
    userName: string
}

const InfoText = (props: InfoTextProps) => (
    <Accordion variant="contained" defaultValue="information">
        <Accordion.Item value="information">
            <Accordion.Control>
                <Text
                    style={{
                        fontWeight: 'bolder',
                    }}
                >
                    Welcome {props.userName}!
                </Text>
            </Accordion.Control>
            <Accordion.Panel style={{ fontSize: '14px' }}>
                <Text style={{ marginBottom: '10px' }}>
                    Every Bigblue employee receives BSPCEs when they join
                    Bigblue and when they are promoted, see{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.maddyness.com/2019/01/29/maddynoob-tout-comprendre-aux-bspce-ou-presque-en-moins-de-10-minutes/"
                    >
                        this article
                    </a>{' '}
                    for a better understanding of BSPCEs, see{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.notion.so/bigblue/Bigblue-s-salary-grid-cfa2692bc253422c9d0c2809434c03f4"
                    >
                        this Notion doc
                    </a>{' '}
                    for Bigblue specific information on BSPCEs and for more
                    details on the corresponding legal texts, see{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://bofip.impots.gouv.fr/bofip/1260-PGP.html/identifiant=BOI-RSA-ES-20-40-20210203"
                    >
                        this official tax bulletin
                    </a>
                    .
                </Text>
                <Text style={{ marginBottom: '10px' }}>
                    The simulator below allows you to play with the expected
                    valuation and dilution of Bigblue to see how much your
                    BSPCEs could be worth. On the right, a table with the target
                    values is available.
                </Text>
                <Text>
                    The table below shows the options you received and simulates
                    the benefit you would make by exercising your options at
                    different times and selling them when Bigblue reaches{' '}
                    <b>
                        the expected valution and dilution you entered in the
                        simulator
                    </b>
                    . Also note that this calculator{' '}
                    <b>does not account for taxes</b> but you can expect to be
                    taxed at 47,2% if you have been in Bigblue for less than 3
                    years or 30% otherwise (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.impots.gouv.fr/particulier/questions/jai-vendu-des-bons-de-souscription-de-parts-de-createurs-dentreprise-ou-bspce"
                    >
                        source
                    </a>
                    ).
                </Text>
            </Accordion.Panel>
        </Accordion.Item>
    </Accordion>
)

export { InfoText }
