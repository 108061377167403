import { Slider, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";
import React from "react";

interface SimulatorProps {
  expectedVal: number;
  expectedDil: number;
  dilShares: number;
  setExpectedVal: (arg: number) => void;
  setExpectedDil: (arg: number) => void;
}

const Simulator = (props: SimulatorProps) => {
  return (
    <div
      style={{
        flex: "1 1 auto",
      }}
    >
      <div style={{ marginBottom: "25px" }}>
        <div style={{ display: "flex" }}>
          Expected Valuation{" "}
          <b style={{ marginLeft: "5px" }}>
            {formatMoneyValue(props.expectedVal)}
          </b>
          <Tooltip label="Expected valuation of Bigblue at the time you use your options. The current valuation is €47.7m">
            <div>
              <IconInfoCircle
                style={{ maxHeight: "20px", marginLeft: "5px" }}
              ></IconInfoCircle>
            </div>
          </Tooltip>
        </div>
        {
          // Expected valuation value is put under the form ((n%9)+1)*10^(n/9) where n is an int
          // and this Slider takes n as its value.
        }
        <Slider
          value={getNFromValue(props.expectedVal)}
          min={63}
          max={99}
          step={1}
          label={null}
          onChange={(n) => props.setExpectedVal(getValueFromN(n))}
          marks={[
            {
              value: 63,
              label: formatMoneyValue(getValueFromN(63)),
            },
            {
              value: 99,
              label: formatMoneyValue(getValueFromN(99)),
            },
          ]}
        />
      </div>
      <div style={{ marginBottom: "25px" }}>
        <div style={{ display: "flex" }}>
          Expected Dilution{" "}
          <b style={{ marginLeft: "5px" }}>
            {(props.expectedDil * 100).toLocaleString() + "%"}
          </b>
          <Tooltip label="Expected dilution of existing shares. It happens when new shares are issued at fundraisings">
            <div>
              <IconInfoCircle
                style={{ maxHeight: "20px", marginLeft: "5px" }}
              ></IconInfoCircle>
            </div>
          </Tooltip>
        </div>
        <Slider
          value={props.expectedDil}
          label={null}
          min={0}
          max={1}
          precision={2}
          step={0.01}
          onChange={(val) => props.setExpectedDil(val)}
          marks={[
            {
              value: 0,
              label: "0%",
            },
            {
              value: 1,
              label: "100%",
            },
          ]}
        />
      </div>
    </div>
  );
};

// getNFromValue computes the int n such that
// value = ((n%9)+1)*10^(n/9)
const getNFromValue = (value: number): number => {
  const quot = Math.floor(Math.log10(value));
  const rest = Math.floor(value / 10 ** quot) - 1;
  return quot * 9 + rest;
};

// getValueFromN performs the opposite operation as getNFromValue, it returns
// ((n%9)+1)*10^(n/9)
const getValueFromN = (n: number): number => {
  return ((n % 9) + 1) * 10 ** Math.floor(n / 9);
};

const moneyValueSuffixMap = new Map<number, string>([
  [2, "m"],
  [3, "bn"],
]);

// formatValue formats a number corresponding to a money value to the form €100m.
// Can format all numbers between 1 million (1m) and 999 billion (999bn)
const formatMoneyValue = (value: number): string => {
  const powerOfThousand = Math.floor(Math.floor(Math.log10(value)) / 3);
  if (!moneyValueSuffixMap.get(powerOfThousand)) {
    return value.toString();
  }
  return (
    "€" +
    Math.floor(value / (10 ** 3) ** powerOfThousand) +
    moneyValueSuffixMap.get(powerOfThousand)
  );
};

export { Simulator };
