import { MantineProvider, Text, Title } from "@mantine/core";
import {
  NotificationsProvider,
  showNotification,
} from "@mantine/notifications";
import { CredentialResponse, GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import "./App.css";
import { Grant, GrantsTable } from "./Components/GrantsTable";
import { ImpersonationAlert } from "./Components/ImpersonationAlert";
import { InfoText } from "./Components/InfoText";
import { LoginModal } from "./Components/Login";
import { MilestonesTable } from "./Components/MilestonesTable";
import { NoGrantPage } from "./Components/NoGrantPage";
import { Simulator } from "./Components/Simulator";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const NUMBER_OF_FULLY_DILUTED_SHARES = 732586;

const API_URL =
  window.localStorage.getItem("API_URL") ?? process.env.REACT_APP_API_URL;

const TOKEN_EXPIRED_ERROR = "Unauthorized - Token is expired\n";

export interface Data {
  user: User;
  grants: Grant[];
}

interface User {
  id: number;
  name: string;
  email: string;
  is_admin?: boolean;
}

function App() {
  const [data, setData] = React.useState<Data>();

  const [expectedVal, setExpectedVal] = React.useState(600000000);
  const [expectedDil, setExpectedDil] = React.useState(0.5);

  const [impersonatedUserEmail, setImpersonatedUserEmail] = React.useState<
    string | undefined
  >(undefined);

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  React.useEffect(() => {
    // TODO token may be outdated because google certificates expires so we need to check that
    const token = localStorage.getItem("google_login_token");
    if (!token) {
      setLoginModalOpen(true);
    }
    if (token && !data) {
      const fetch = async () => {
        try {
          const res = await fetchUserData(token);
          setData(res);
        } catch (e) {
          setLoginModalOpen(true);
          handleError(e, "Error while fetching user");
        }
      };
      fetch();
    }
  }, [data]);

  const onLoginSuccess = async (credentialResponse: CredentialResponse) => {
    const creds = credentialResponse.credential;
    if (!creds) {
      showNotification({
        title: "Login error",
        message: "No credential provided",
        color: "red",
        autoClose: 5000,
      });
    } else {
      try {
        const res = await fetchUserData(creds);
        localStorage.setItem("google_login_token", creds);
        setData(res);
        setLoginModalOpen(false);
      } catch (e) {
        handleError(e, "Error while fetching user");
      }
    }
  };

  const onLoginError = () => {
    showNotification({
      message: "Login error",
      color: "red",
      autoClose: 5000,
    });
  };

  const downloadData = async (resource: string) => {
    const token = localStorage.getItem("google_login_token");
    if (token) {
      const data = await fetchData(token, resource);
      const current = new Date();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(
        new Blob([data], { type: "data:text/csv;charset=utf-8," })
      );
      link.download = `${resource}_${
        current.getMonth() + 1
      }_${current.getDate()}_${current.getFullYear()}_${current.getHours()}h_${current.getMinutes()}.csv`;
      link.click();
    }
  };

  const handleError = (err: any, textError: string) => {
    if (err instanceof Error) {
      if (err.message === TOKEN_EXPIRED_ERROR) {
        setLoginModalOpen(true);
        showNotification({
          message: "Please login again",
          autoClose: 5000,
        });
      } else {
        showNotification({
          title: textError,
          message: err.message,
          color: "red",
          autoClose: 5000,
        });
      }
    } else {
      showNotification({
        message: textError,
        color: "red",
        autoClose: 5000,
      });
    }
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID ?? ""}>
      <MantineProvider>
        <NotificationsProvider>
          <div className="App">
            <LoginModal
              opened={loginModalOpen}
              onSuccess={onLoginSuccess}
              onError={onLoginError}
            />
            {!loginModalOpen &&
              (data?.user.is_admin || impersonatedUserEmail) && (
                <ImpersonationAlert
                  impersonatedUserEmail={impersonatedUserEmail}
                  onUserChange={async (imp?: string) => {
                    try {
                      const token = localStorage.getItem("google_login_token");
                      if (token) {
                        const value = await fetchUserData(token, imp);
                        setImpersonatedUserEmail(imp);
                        setData(value);
                      }
                    } catch (e) {
                      handleError(e, "Error while fetching impersonated user");
                    }
                  }}
                  onDownload={async () => {
                    try {
                      await downloadData("users");
                      await downloadData("grants");
                    } catch (e) {
                      handleError(e, "Download failed");
                    }
                  }}
                  onUpload={async (file: File | null) => {
                    try {
                      const token = localStorage.getItem("google_login_token");
                      if (token && file) {
                        await uploadData(token, file);
                        showNotification({
                          message: "Upload succeeded",
                          color: "green",
                          autoClose: 3000,
                        });
                      }
                    } catch (e) {
                      handleError(e, "Upload failed");
                    }
                  }}
                />
              )}
            {!loginModalOpen && data?.user && (
              <>
                <header className="App-header">
                  Bigblue Stock Options Calculator
                </header>
                <div className="App-body">
                  {data.grants.length === 0 ? (
                    <NoGrantPage name={data.user.name} />
                  ) : (
                    <div
                      style={{
                        margin: "25px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        minHeight: "100%",
                      }}
                    >
                      <InfoText userName={data.user.name} />
                      <div
                        style={{
                          marginTop: "50px",
                          marginBottom: "50px",
                        }}
                      >
                        <Title order={3}>Simulator</Title>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            columnGap: "50px",
                            alignItems: "center",
                          }}
                        >
                          <Simulator
                            expectedVal={expectedVal}
                            expectedDil={expectedDil}
                            dilShares={NUMBER_OF_FULLY_DILUTED_SHARES}
                            setExpectedDil={setExpectedDil}
                            setExpectedVal={setExpectedVal}
                          />
                          <MilestonesTable
                            setExpectedDil={setExpectedDil}
                            setExpectedVal={setExpectedVal}
                          />
                        </div>
                      </div>
                      <Title order={3}>Your BSPCEs</Title>
                      <GrantsTable
                        grants={data.grants}
                        expectedVal={expectedVal}
                        expectedDil={expectedDil}
                        dilShares={NUMBER_OF_FULLY_DILUTED_SHARES}
                      />
                    </div>
                  )}
                </div>
                <Text
                  color="dimmed"
                  size={14}
                  style={{
                    margin: "10px",
                  }}
                >
                  This calculator is only meant to help you estimate the
                  potential value of your stock options (BSPCE). It does not
                  represent a commitment from Bigblue in any capacity.
                </Text>
              </>
            )}
          </div>
        </NotificationsProvider>
      </MantineProvider>
    </GoogleOAuthProvider>
  );
}

// TODO cache
async function fetchUserData(
  token: string,
  impersonatedUserEmail?: string
): Promise<Data> {
  const res = await fetch(API_URL + "/userdata", {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "X-Bigblue-Impersonate": impersonatedUserEmail ?? "",
    }),
  });
  if (!res.ok) {
    throw new Error(`${res.statusText} - ${await res.text()}`);
  }
  return res.json();
}

async function fetchData(token: string, resource: string): Promise<string> {
  const res = await fetch(API_URL + `/${resource}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
  if (!res.ok) {
    throw new Error(`${res.statusText} - ${await res.text()}`);
  }
  if (!res.body) {
    throw new Error("empty body");
  }
  const result = await res.body.getReader().read();
  return new TextDecoder("utf-8").decode(result.value);
}

async function uploadData(token: string, file: File) {
  const res = await fetch(API_URL + `/uploaddata`, {
    method: "post",
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/csv",
    }),
    body: file,
  });
  if (!res.ok) {
    throw new Error(`${res.statusText} - ${await res.text()}`);
  }
}

export default App;
