import {
    Button,
    FileInput,
    Modal,
    Text,
    ThemeIcon,
    Tooltip,
} from '@mantine/core'
import { IconFileUpload } from '@tabler/icons'
import React from 'react'

interface UploadProps {
    onUpload: (file: File | null) => Promise<void>
    style?: React.CSSProperties | undefined
}

const Upload = (props: UploadProps) => {
    const [fileValue, setFileValue] = React.useState<File | null>(null)
    const [modalOpen, setModalOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const hiddenFileInput = React.useRef<HTMLButtonElement>(null)

    return (
        <div style={props.style}>
            <FileInput
                value={fileValue}
                ref={hiddenFileInput}
                onChange={(payload: File | null) => {
                    setFileValue(payload)
                    setModalOpen(true)
                }}
                style={{ display: 'none' }}
            />
            <Tooltip label="Upload data">
                <ThemeIcon
                    color="#1f70f2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        hiddenFileInput.current?.click()
                    }}
                >
                    <IconFileUpload />
                </ThemeIcon>
            </Tooltip>
            <Modal
                opened={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                    setFileValue(null)
                }}
                title="Update user and BSPCE data"
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        gap: '20px',
                    }}
                >
                    <Text>
                        {`Data will be updated based on file ${fileValue?.name}. This will
                            erase previous data.`}
                    </Text>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            await props.onUpload(fileValue)
                            setLoading(false)
                            setModalOpen(false)
                            setFileValue(null)
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export { Upload }
