import { Table, Text, Tooltip } from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons'
import styled from 'styled-components'

const SimulatedTd = styled.td`
    background-color: #fff4e6;
`
const SimulatedTh = styled.th`
    background-color: #fff4e6;
`
const DivWithTooltip = styled.div`
    display: flex;
    align-items: stretch;
    height: 20px;
`

export interface Grant {
    id: number
    email: string
    shares: number
    strikePrice: number
    createDate: string
    cliffDate: string
}

interface GrantsTableProps {
    grants: Grant[]
    expectedVal: number
    expectedDil: number
    dilShares: number
    computedValueType?: ComputedValueType
}

enum Stage {
    BEFORE_CLIFF = 1,
    AFTER_CLIFF_NOT_FULLY_VESTED = 2,
    AFTER_CLIFF_FULLY_VESTED = 3,
}

export enum ComputedValueType {
    SELL_VALUE = 1,
    BENEFIT = 2,
}

const CustomTooltip = (props: {
    label: string
    multiline?: boolean
    width?: number
}) => (
    <Tooltip
        label={props.label}
        multiline={props.multiline}
        width={props.width}
    >
        <div>
            <IconInfoCircle
                style={{
                    maxHeight: '20px',
                    marginLeft: '5px',
                }}
            ></IconInfoCircle>
        </div>
    </Tooltip>
)

const GrantsTable = (props: GrantsTableProps) => {
    let sumToDateValue = 0
    let sumFullyVestedValue = 0

    const elements = props.grants.map((grant) => {
        const cliffDate = new Date(grant.cliffDate)

        // Compute the number of elapsed quarters after cliff and the date of the next milestone quarter.
        // If elapsedQuartersAfterCliff = -1 it means that the cliff date hasn't been reached.
        let elapsedQuartersAfterCliff = -1
        let nextQuarterDate = new Date(grant.cliffDate)
        while (nextQuarterDate.getTime() <= Date.now()) {
            elapsedQuartersAfterCliff++
            nextQuarterDate.setMonth(nextQuarterDate.getMonth() + 3)
        }

        const stage =
            elapsedQuartersAfterCliff === -1
                ? Stage.BEFORE_CLIFF
                : elapsedQuartersAfterCliff >= 12
                ? Stage.AFTER_CLIFF_FULLY_VESTED
                : Stage.AFTER_CLIFF_NOT_FULLY_VESTED

        const vestedPercentage =
            Date.now() < cliffDate.getTime()
                ? 0
                : 4 / 16 + Math.min(elapsedQuartersAfterCliff, 12) * (1 / 16)

        const fullyVestedValue =
            (props.expectedVal /
                ((1 / (1 - props.expectedDil)) * props.dilShares) -
                (!props.computedValueType ||
                props.computedValueType === ComputedValueType.BENEFIT
                    ? grant.strikePrice
                    : 0)) *
            grant.shares

        const nextMilestoneVestedPercentage =
            stage === Stage.BEFORE_CLIFF
                ? 4 / 16
                : stage === Stage.AFTER_CLIFF_NOT_FULLY_VESTED
                ? 4 / 16 + (elapsedQuartersAfterCliff + 1) * (1 / 16)
                : 1

        const fullyVestedDate = new Date(grant.cliffDate)
        fullyVestedDate.setFullYear(cliffDate.getFullYear() + 3)

        const nextMilestoneDate =
            stage === Stage.BEFORE_CLIFF
                ? cliffDate
                : stage === Stage.AFTER_CLIFF_NOT_FULLY_VESTED
                ? nextQuarterDate
                : fullyVestedDate

        sumToDateValue += fullyVestedValue * vestedPercentage
        sumFullyVestedValue += fullyVestedValue

        return {
            createDate: new Date(grant.createDate).toISOString().substr(0, 10),
            shares: grant.shares,
            strikePrice: grant.strikePrice,
            cliffDate: new Date(grant.cliffDate).toISOString().substr(0, 10),
            toDate: (fullyVestedValue * vestedPercentage).toLocaleString(
                undefined,
                {
                    maximumFractionDigits: 0,
                }
            ),
            nextMilestone: `${(
                fullyVestedValue * nextMilestoneVestedPercentage
            ).toLocaleString(undefined, {
                maximumFractionDigits: 0,
            })} (${nextMilestoneDate.toISOString().substr(0, 10)})`,
            fullyVested: `${fullyVestedValue.toLocaleString(undefined, {
                maximumFractionDigits: 0,
            })} (${fullyVestedDate.toISOString().substr(0, 10)})`,
        }
    })

    const rows = elements
        .map((element, n) => (
            <tr key={n}>
                <td>{element.createDate}</td>
                <td>{element.cliffDate}</td>
                <td>
                    {element.shares.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                    })}
                </td>
                <td>{'€' + element.strikePrice}</td>
                <SimulatedTd>{'€' + element.toDate}</SimulatedTd>
                <SimulatedTd>{'€' + element.nextMilestone}</SimulatedTd>
                <SimulatedTd>{'€' + element.fullyVested}</SimulatedTd>
            </tr>
        ))
        .concat(
            <tr>
                <td>
                    <b>Total</b>
                </td>
                <td></td>
                <td>
                    <b>
                        {elements
                            .reduce((prev, curr) => curr.shares + prev, 0)
                            .toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}
                    </b>
                </td>
                <td></td>
                <SimulatedTd>
                    <b>
                        {'€' +
                            sumToDateValue.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}
                    </b>
                </SimulatedTd>
                <SimulatedTd></SimulatedTd>
                <SimulatedTd>
                    <b>
                        {'€' +
                            sumFullyVestedValue.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                            })}
                    </b>
                </SimulatedTd>
            </tr>
        )

    return (
        <div>
            <Text color="dimmed">
                The benefits displayed are simulations based on the expected
                valuation and dilution you entered.
            </Text>
            <Table style={{ margin: '5px' }}>
                <thead>
                    <tr>
                        <th>Issue date</th>
                        <th>
                            <DivWithTooltip>
                                Cliff date{' '}
                                <CustomTooltip label="Date on which your BSPCEs start to be unlocked, starting at 25%" />
                            </DivWithTooltip>
                        </th>
                        <th>
                            <DivWithTooltip>
                                Number of options{' '}
                                <CustomTooltip label="Number of options you can exercise at the end of your vesting period" />
                            </DivWithTooltip>
                        </th>
                        <th>
                            <DivWithTooltip>
                                Strike price{' '}
                                <CustomTooltip label="Price at which you can exercise each option" />
                            </DivWithTooltip>
                        </th>
                        <SimulatedTh>
                            <DivWithTooltip>
                                Benefit to date{' '}
                                <CustomTooltip label="Benefit you would make if you leave the company today, exercise all your unlocked options and sell them when Bigblue reaches the input valuation and dilution" />
                            </DivWithTooltip>
                        </SimulatedTh>
                        <SimulatedTh>
                            <DivWithTooltip>
                                Benefit at next milestone{' '}
                                <CustomTooltip
                                    multiline
                                    width={750}
                                    label="
                                Benefit you would make if you leave the company after the next milestone date, exercise all your unlocked options and sell them when Bigblue reaches the input valuation and dilution.
                                The next milestone date is either the cliff date (+25%) if not reached yet or the next quarter (+6.25%)
                                "
                                />
                            </DivWithTooltip>
                        </SimulatedTh>
                        <SimulatedTh>
                            <DivWithTooltip>
                                Benefit when fully vested{' '}
                                <CustomTooltip
                                    multiline
                                    width={750}
                                    label="Benefit you would make if you leave the company at the end of your vesting period, exercise all your options and sell them when Bigblue reaches the input valuation and dilution OR if there is an exit while you are still employed"
                                />
                            </DivWithTooltip>
                        </SimulatedTh>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </div>
    )
}

export { GrantsTable }
