import { Text } from '@mantine/core'

interface NoGrantPageProps {
    name: string
}

const NoGrantPage = (props: NoGrantPageProps) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Text>
            Sorry {props.name} but you don't have any grant for now... :'(
        </Text>
    </div>
)

export { NoGrantPage }
