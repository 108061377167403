import * as React from 'react'

interface BigBlueShortLogoProps {
    size: number
    style?: React.CSSProperties
}

const BigBlueShortLogo = ({
    size,
    style,
}: BigBlueShortLogoProps): React.ReactElement => {
    return (
        <svg
            style={style}
            width={size}
            height={size}
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="128" height="128" fill="#446AEB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51 30C54.3137 30 57 32.6863 57 36V52H73C84.598 52 94 61.402 94 73V74C94 85.0457 85.0457 94 74 94H65C53.9543 94 45 85.0457 45 74V64H36C32.6863 64 30 61.3137 30 58C30 54.6863 32.6863 52 36 52H45V36C45 32.6863 47.6863 30 51 30ZM57 64V74C57 77.42 60.5817 81.5 65 81.5H74C78.4183 81.5 82.5 77.4183 82.5 73V70C82.5 66.6863 79.8137 64 76.5 64H57Z"
                fill="white"
            />
        </svg>
    )
}

export { BigBlueShortLogo }
